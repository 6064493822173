<template>
  <component :is="tag" class="key-figures parent-bg">
    <div class="section-inner">
      <h2 v-if="blok.title" class="title size-xl align-center">
        {{ blok.title }}
      </h2>
      <div v-if="text?.length" class="heading size-m--d" v-html="text"></div>
      <div
        v-if="keyFiguresLength"
        :class="`key-figures-list${
          blok.title || text?.length ? ' margin-top' : ''
        } has-${keyFiguresLength}-items`"
      >
        <StoryblokComponent
          v-for="keyFigure in blok.keyFiguresList"
          :key="keyFigure._uid"
          :blok="keyFigure"
          class="key-figure-item"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok } = toRefs(props); // eslint-disable-line
const { text } = useRichText(blok.value);
const keyFiguresLength = blok.value.keyFiguresList?.length;
</script>

<style lang="scss">
.background-key-figures.alternate {
  @include pair-3;
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--parent-bg);
    }
  }
}
</style>

<style lang="scss" scoped>
.title {
  margin-bottom: 0.75rem;
}
.key-figures {
  &-list {
    display: grid;
    grid-gap: 0.625rem;
    @include for-desktop-up {
      grid-template-columns: repeat(18, 1fr);
      flex-wrap: wrap;
    }
  }
}
@include for-desktop-up {
  .has-2-items .key-figure-item,
  .has-4-items .key-figure-item,
  .has-5-items .key-figure-item:first-child,
  .has-5-items .key-figure-item:nth-child(2) {
    grid-column: span 9;
  }
}
</style>
